import React from "react"
import LayoutComponent from "../../../components/documentation-layout/Layout"

const TestSuiteActions = () => {
  return (
    <LayoutComponent>
      <h2>Test Suite Actions</h2>
      <div>
        <strong>View Test Cases</strong>
        <p>
          This allows you to inspect all the test cases within a selected test
          suite. This provides a detailed list of individual test cases, their
          descriptions, execution status, and other relevant data. Use this
          feature to ensure all test cases are properly defined and updated
          before executing the suite.
        </p>
      </div>

      <div>
        <strong>Suspend Test Suite</strong>
        <p>
          This feature allows you to suspend a test suite, preventing it from
          being added to a test cycle or executed. When suspended, no new test
          cases can be added to the suite. This is helpful when the suite needs
          to be temporarily paused due to changes in testing requirements,
          configuration issues, or other reasons. The test suite can be restored
          later, allowing for further updates and execution.
        </p>
      </div>

      <div>
        <strong>Test Suite Metrics</strong>
        <p>
          The <strong>Test Suite Metrics</strong> section provides insights into
          the overall performance of a test suite. Key metrics include:
        </p>
        <ul>
          <li>
            <strong>Execution Percentage:</strong> The percentage of test cases
            that have been executed out of the total planned for the suite.
          </li>
          <li>
            <strong>Pass Percentage:</strong> The percentage of test cases that
            have successfully passed during execution.
          </li>
        </ul>
      </div>
    </LayoutComponent>
  )
}

export default TestSuiteActions
